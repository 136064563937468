export const BASIC = ['Attack', 'Escape', 'Peach', 'Fire Attack', 'Lightning Attack', 'Wine'];

export const EQUIPMENT = {
    'Lightning': 'Lightning',
    'Capture': 'Capture',
    'Starvation': 'Starvation',
    'Crossbow': 'Weapon',
    'Black Pommel': 'Weapon',
    'Ice Sword': 'Weapon',
    'Gender Swords': 'Weapon',
    'Green Dragon Blade': 'Weapon',
    'Serpent Spear': 'Weapon',
    'Axe': 'Weapon',
    'Sky Scorcher': 'Weapon',
    'Longbow': 'Weapon',
    'Ancient Scimitar': 'Weapon',
    'Fire Fan': 'Weapon',
    'Eight Trigrams': 'Shield',
    'Black Shield': 'Shield',
    'Wood Armor': 'Shield',
    'Silver Helmet': 'Shield',
    'Red Hare': '-1',
    'Da Yuan': '-1',
    'Zi Xing': '-1',
    'Di Lu': '+1',
    'Shadow Runner': '+1',
    'Storm Runner': '+1',
    'Hua Liu': '+1',
};
